<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="MyPlayer"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('wanjia_xiangqing') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <div>
        <v-card
          flat
          class="rounded-xl mt-3 pa-3"
        >
          <v-list-item>
            <v-list-item-avatar v-if="playerinfo.avatar">
              <v-img :src="require('../assets/img/avatar/'+playerinfo.avatar+'.png')" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="mb-1">
                {{ playerinfo.username }}
              </v-list-item-title>
              <v-list-item-subtitle class="text--disabled">
                UID: <span>{{ playerinfo.id }}</span>
                <v-btn
                  icon
                  small
                  class="ml-1"
                  @click="doCopy(playerinfo.id)"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>
                <div class="d-flex align-center">
                  <img
                    width="16"
                    height="16"
                    src="../assets/img/icons/coin.svg"
                    class="mr-1"
                  >
                  {{ playerinfo.assets?.available }}
                </div>
              </v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-card>

        <v-row
          dense
          class="my-4"
        >
          <v-col
            class="text-center"
          >
            <v-btn
              fab
              depressed
              dark
              color="btn_bg"
              class="rounded-xl"
              @click="showTransfer = true"
            >
              <v-icon>
                mdi-send
              </v-icon>
            </v-btn>
            <div class="text--secondary text-body-2 mt-2">
              {{ $t('zhuanfen') }}
            </div>
          </v-col>
          <v-col
            class="text-center"
          >
            <v-btn
              fab
              depressed
              dark
              color="btn_bg"
              class="rounded-xl"
              @click="showCashout = true"
            >
              <v-icon>
                mdi-database-arrow-down
              </v-icon>
            </v-btn>
            <div class="text--secondary text-body-2 mt-2">
              {{ $t('jianfen') }}
            </div>
          </v-col>
          <v-col
            class="text-center"
          >
            <v-btn
              fab
              depressed
              dark
              color="btn_bg"
              class="rounded-xl"
              @click="edit_user = true"
            >
              <v-icon>
                mdi-account-edit
              </v-icon>
            </v-btn>
            <div class="text--secondary text-body-2 mt-2">
              {{ $t('bianji') }}
            </div>
          </v-col>
          <v-col
            class="text-center"
          >
            <v-btn
              fab
              depressed
              dark
              color="btn_bg"
              class="rounded-xl"
              @click="gotologs"
            >
              <v-icon>
                mdi-clipboard-text-clock
              </v-icon>
            </v-btn>
            <div class="text--secondary text-body-2 mt-2">
              {{ $t('jilu') }}
            </div>
          </v-col>
        </v-row>

        <v-card
          flat
          class="rounded-xl mt-3"
        >
          <v-list>
            <v-subheader class="opacity-5">
              {{ $t('wanjia_xinxi') }}
            </v-subheader>
            <v-list-item>
              <v-list-item-subtitle>
                {{ $t('zhuanru_zong') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ playerinfo.total_topup }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                {{ $t('zhuanchu_zong') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ playerinfo.total_cashout }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                {{ $t('reg_time') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ playerinfo.reg_time }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>
                {{ $t('login_last') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                {{ playerinfo.login_time }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t('beizhu') }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action style="max-width: 70%;">
                <small class="opacity-6">
                  {{ playerinfo.remark }}
                </small>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card
          flat
          class="rounded-xl my-3"
        >
          <v-card-text class="px-0">
            <v-subheader class="opacity-5">
              {{ $t('jilu_jinqi') }}
            </v-subheader>
            <v-list class="pt-0">
              <v-list-item
                v-for="(item, index) in last10log"
                :key="'l'+index"
                two-line
                @click="showdetail(item)"
              >
                <v-list-item-avatar
                  v-if="item.type2=='receive'"
                  tile
                >
                  <v-icon
                    color="blue"
                    class="blue lighten-5 rounded-lg"
                  >
                    mdi-bank-transfer-in
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-avatar
                  v-if="item.type2=='send'"
                  tile
                >
                  <v-icon
                    color="orange"
                    class="orange lighten-5 rounded-lg"
                  >
                    mdi-bank-transfer-out
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-if="item.type2=='receive'">
                    {{ $t('zhuanru') }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.type2=='send'">
                    {{ $t('zhuanchu') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.created }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action
                  v-if="item.type2=='receive'"
                  class="text-right"
                >
                  <v-list-item-subtitle>
                    <strong>+{{ item.amount }}</strong>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip
                      v-if="item.status==0"
                      x-small
                      color="lighten-5"
                    >
                      {{ $t('chulizhong') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==1"
                      x-small
                      color="green lighten-5"
                      text-color="green"
                    >
                      {{ $t('yiwancheng') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==-1"
                      x-small
                      color="red lighten-5"
                      text-color="red"
                    >
                      {{ $t('shibai') }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.type2=='send'"
                  class="text-right"
                >
                  <v-list-item-subtitle>
                    <strong>-{{ item.amount }}</strong>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip
                      v-if="item.status==0"
                      x-small
                      color="lighten-5"
                    >
                      {{ $t('chulizhong') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==1"
                      x-small
                      color="green lighten-5"
                      text-color="green"
                    >
                      {{ $t('yiwancheng') }}
                    </v-chip>
                    <v-chip
                      v-if="item.status==-1"
                      x-small
                      color="red lighten-5"
                      text-color="red"
                    >
                      {{ $t('shibai') }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div class="text-center">
              <v-btn
                outlined
                depressed
                small
                class="rounded-pill"
                color="primary"
                @click="gotologs"
              >
                {{ $t('gengduo') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>

    <template v-if="showTransfer">
      <Transfer
        :to-role="'player'"
        :transitem="playerinfo"
        @close_transfer="close_transfer"
      />
    </template>

    <template v-if="showCashout">
      <Cashout
        :from-role="'player'"
        :transitem="playerinfo"
        @close_cashout="close_cashout"
      />
    </template>

    <template v-if="edit_user">
      <Editinfo
        :role="'player'"
        :userinfo="playerinfo"
        @close_editinfo="close_editinfo"
      />
    </template>
    
    <v-bottom-sheet
      v-model="detail_sheet"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl pb-10"
      >
        <v-container :ref="'detail'+'2'">
          <v-row
            dense
            align="center"
          >
            <v-col cols="4">
              <v-btn
                icon
                @click="detail_sheet = false"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="text-center"
            >
              {{ $t('xiangqing') }}
            </v-col>
          </v-row>
        </v-container>

        <v-list
          class="py-0 px-3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('tijiao_shijian') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail_item.created }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('dingdanbianhao') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail_item.orderno }}
                <v-btn
                  small
                  icon
                  @click="doCopy2(detail_item.orderno, 'detail2')"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('shuliang') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="detail_item.type2=='receive'">
              <v-list-item-subtitle>
                +{{ detail_item.amount }} {{ $t('fen') }}
              </v-list-item-subtitle>
            </v-list-item-action>
            <v-list-item-action v-if="detail_item.type2=='send'">
              <v-list-item-subtitle>
                -{{ detail_item.amount }} {{ $t('fen') }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('zhuangtai') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                <v-chip
                  v-if="detail_item.status==0"
                  small
                  color="lighten-5"
                >
                  {{ $t('chulizhong') }}
                </v-chip>
                <v-chip
                  v-if="detail_item.status==1"
                  small
                  color="green lighten-5"
                  text-color="green"
                >
                  {{ $t('yiwancheng') }}
                </v-chip>
                <v-chip
                  v-if="detail_item.status==-1"
                  small
                  color="red lighten-5"
                  text-color="red"
                >
                  {{ $t('shibai') }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('beizhu_jine') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail_item.remark_money }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('beizhu_neirong') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail_item.remark }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Transfer from '@/views/pages/transfer.vue'
import Cashout from '@/views/pages/cashout.vue'
import Editinfo from './pages/editinfo.vue';
  export default {
    components:{
      Transfer,
      Cashout,
      Editinfo
    },
    data: () => ({
      showTransfer: false,
      showCashout: false,
      detail_sheet: false,
      edit_user: false,
      lastrecord: [
        {

        }
      ],
      playerinfo: {},
      last10log: [],
      detail_item: {},
    }),

    computed: {
      
    },
    created() {
      console.log(this.$route.query.id)
    },
    mounted() {
      this.getplayerinfo()
      this.playerlast10log()
    },
    methods: {
      getplayerinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          id: this.$route.query.id
        }
        this.$server.playerinfo(paramObj).then((response) => {
          if(response.code==200){
            this.playerinfo = response.data
          }
        })
      },
      playerlast10log() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          id: this.$route.query.id
        }
        this.$server.playerlast10log(paramObj).then((response) => {
          if(response.code==200){
            this.last10log = response.data.data
          }
        })
      },
      close_transfer() {
        this.showTransfer = false
      },
      close_cashout() {
        this.showCashout = false
      },
      close_editinfo() {
        this.edit_user = false
        this.getplayerinfo()
      },
      showdetail(item) {
        this.detail_item = item
        this.detail_sheet = true
      },
      gotologs() {
        this.$router.push({
          name: 'Record',
          query:{
            type: 'player',
            id: this.$route.query.id,
          }
        })
      },
      doCopy: function (text) {
        text = ''+text//转string
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
      doCopy2: function (text, ref) {
        text = ''+text//转string
        this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  }
</script>